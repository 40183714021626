.block-user {
  // Fix text overflow
  .lumx-user-block__wrapper,
  .lumx-user-block__name,
  .lumx-user-block__fields {
    min-width: 0;
    > span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
