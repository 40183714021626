.block-playlist-button {
    display: inline-block;
    padding: 0;
    border: 0;
    background-color: transparent;
    vertical-align: middle;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    color: inherit;
    cursor: pointer;
    transition: opacity 0.3s;

    &:active {
        opacity: 0.95;
    }

    &:focus-visible {
        outline-style: solid;
        outline-width: 2px;
        outline-offset: 2px;
    }

    &--dark {
        &:focus-visible {
            outline-color: var(--lumx-color-dark-N);
        }
    }

    &--light {
        &:focus-visible {
            outline-color: var(--lumx-color-light-N);
        }
    }
    
    &--is-truncated {
        overflow: hidden;
    }
}
