@import '@lumapps/lumx/scss/variables.scss';

$slideshow-spacing: $lumx-spacing-unit * 1.5;

.widget-content--block-slideshow:not(.widget-content--has-fixed-height)  {
    // The negative margin used in grouped/ungrouped variant will make the content overflow.
    // We choose to make the overflow visible because the slideshow items could have shadows that should overflow
    // outside of the widget content.
    overflow: visible !important;

    // If supported, we will prefer using clip so that the border radius is correctly displayed.
    @supports (overflow:clip) {
        overflow: clip !important;
    }
}

.block-slideshow {
    $this: &;

    &--cover,
    &--side {
        position: relative;
    }

    &--grouped,
    &--ungrouped {
        margin: $slideshow-spacing * -1;
    }

    .widget--is-full-height &--cover,
    .widget--is-full-height &--side,
    .widget-content--has-fixed-height &--cover,
    .widget-content--has-fixed-height &--side {
        &,
        .#{$lumx-base-prefix}-slideshow,
        .#{$lumx-base-prefix}-slideshow__slides,
        .#{$lumx-base-prefix}-slideshow__wrapper {
            height: 100%;
        }
        .block-page-preview__field--text:has(.block-html, .block-page-preview__excerpt) {
            overflow: hidden;
        }
    }

    &__item-wrapper {
        #{$this}--grouped &,
        #{$this}--ungrouped & {
            padding: $slideshow-spacing;
        }
    }

    &__item {
        height: 100%;
        border: 0 solid lumx-color-variant('dark', 'L5');

        #{$this}--ungrouped & {
            background-color: lumx-color-variant('light', 'N');
            border-radius: $lumx-border-radius;
            padding: $lumx-spacing-unit-huge;

            &--no-padding {
                padding: 0;
            }
        }
    }

    &__controls {
        #{$this}--grouped &,
        #{$this}--ungrouped & {
            margin-top: $lumx-spacing-unit-big;
        }

        #{$this}--cover &,
        #{$this}--side & {
            position: absolute;
            bottom: $lumx-spacing-unit-huge;
        }

        #{$this}--size-l#{$this}--cover & {
            right: $lumx-spacing-unit-huge;
        }

        #{$this}--size-l#{$this}--side & {
            left: calc(70% + #{$lumx-spacing-unit-big});
        }


        #{$this}--size-m#{$this}--side &,
        #{$this}--size-s#{$this}--side & {
            left: calc(50% + #{$lumx-spacing-unit-big});
        }

        #{$this}--size-xs#{$this}--side &,
        #{$this}--size-s#{$this}--cover &,
        #{$this}--size-m#{$this}--cover &{
            left: $lumx-spacing-unit-big;
        }
    }
}
