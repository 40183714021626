@import '@lumapps/lumx/scss/variables.scss';

.widget-content--block-intro {
    padding: $lumx-spacing-unit-huge;
    align-items: var(--lumx-widget-intro-alignment-h);
}

.block-intro {
    @include lumx-typography('intro', 'custom');

    text-align: var(--lumx-widget-intro-text-align);

    .widget--theme-light & {
        color: var(--lumx-widget-intro-theme-light-color);
    }

    .widget--theme-dark & {
        color: var(--lumx-widget-intro-theme-dark-color);
    }
}
