@import '@lumapps/lumx/scss/variables.scss';

.playlist-uploader {

    &.playlist-uploader__input {
      padding: $lumx-spacing-unit-big;
      width: 100%;
      background-color: lumx-color-variant('dark', 'L6');
    }

    .lumx-skeleton-rectangle{
      height: map-get($lumx-sizes, 'l');
    }

    &__error-message {
      margin: $lumx-spacing-unit-huge 0;
    }

    &__title {
      justify-content: space-between;
      margin-bottom: $lumx-spacing-unit-huge;
    }

    &__subtitle {
      @include lumx-typography('subtitle1');
    }

    &__selected-playlist {
      display: flex;
      padding: $lumx-spacing-unit-big;
      background-color: lumx-color-variant('dark', 'L6');

      & article {
        width: calc(100% - #{$lumx-size-m});
      }
    }

    &__remove-icon {
      display: flex;
      flex: 1;
    }
  }
