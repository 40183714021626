@import '@lumapps/lumx/scss/variables.scss';

.post-list-filters {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    gap: $lumx-spacing-unit;
    list-style-type: none;
}
