@import '@lumapps/lumx/scss/variables.scss';

.block-video-figure {
    width: 100%;

    @each $key, $size in $lumx-sizes {
        &--size-#{$key} {
            max-width: $size;
        }
    }
}
