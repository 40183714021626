@import '@lumapps/lumx/scss/variables.scss';

.training-card-skeleton {
    &__box {
        background: $lumx-color-light-N;
        border-radius: var(--lumx-border-radius);
    }
    &__box_with_shadow {
        @include lumx-elevation(2);
    }
    &__img {
        border-radius: var(--lumx-border-radius);
    }
}