.block-video-title {
    &--is-interactive {
        display: flex;

        > * {
            text-align: left;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
