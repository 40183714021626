@import '@lumapps/lumx/scss/variables.scss';

.playlist-skeleton {
    &__videos {
        display: grid;
        grid-template-rows: 1fr;
        grid-auto-rows: 0;
        overflow-y: hidden;
        // ℹ️ min(100%, 220px) allows the grid-item to be responsive if the user device width <= 220px
        grid-template-columns: repeat(auto-fill, minmax(min(100%, 220px), 1fr));
        gap: 0 $lumx-spacing-unit-huge;
    }
}
