@import '@lumapps/lumx/scss/variables.scss';

.widget-content--block-featured-image {
    position: relative;
    padding: 0 0 !important;
    height: 200px;
}

.widget--is-full-height .widget-content--block-featured-image {
    min-height: 200px;
}
