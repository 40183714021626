@import '@lumapps/lumx/scss/variables.scss';

.block-no-results {
    display: flex;
    min-height: $lumx-spacing-unit * 9;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: $lumx-spacing-unit * 2;
    color: $lumx-color-dark-L2;

    &--theme-dark {
        color: $lumx-color-light-L2;
    }

    &--label {
        margin-left: $lumx-spacing-unit;
        font-weight: 500;
    }
}
