@import '@lumapps/lumx/scss/variables.scss';

.toggle-tip-button {
    &__popover {
        max-width: 400px;
    }

    &__close {
        flex-shrink: 0;
    }
}
