.widget-video .widget-content--v2 {
    padding: 0 !important;
}

.block-video--embed {
    position: relative;
    height: 0;
    overflow: hidden;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
}
