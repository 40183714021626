@import '@lumapps/lumx/scss/variables.scss';

.block-video-thumbnail {
    $this: &;

    position: relative;
    border-radius: $lumx-border-radius;

    &--is-interactive {
        &:hover {
            #{$this}__image {
                &::after {
                    opacity: 1;
                    visibility: visible;
                }
            }

            #{$this}__icon {
                opacity: 1;
            }

            #{$this}__video-duration {
                opacity: 0;
                visibility: hidden;
            }
        }

        #{$this}__image {
            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                background-color: $lumx-color-dark-L3;
                border-radius: $lumx-border-radius;
                opacity: 0;
                visibility: hidden;
                transition:
                    opacity 0.3s ease-out,
                    visibility 0.3s ease-out;
            }
        }

        #{$this}__video-duration {
            transition:
                opacity 0.3s ease-out,
                visibility 0.3s ease-out;
        }
    }

    &__icon {
        position: absolute;
        top: 50%;
        left: 50%;
        opacity: 0.7;
        transform: translate3d(-50%, -50%, 0);
        transition: opacity 0.8s ease-out;
    }

    &__video-duration {
        position: absolute;
        bottom: $lumx-spacing-unit-tiny;
        left: $lumx-spacing-unit-tiny;
        line-height: 1;
        padding: $lumx-spacing-unit-tiny $lumx-spacing-unit-regular;
        background-color: $lumx-color-dark-L1;
        border-radius: $lumx-border-radius;
        transition:
            opacity 0.3s ease-out,
            visibility 0.3s ease-out;
    }

    &__image {
        background: #000;

        img {
            // Make sure the image is fully visible (not cropped)
            object-fit: contain !important;
        }
    }

    &__fallback {
        display: block;
        width: 100%;
    }
}
