@import '@lumapps/lumx/scss/variables.scss';

.display-trainings {
    &__list {
        overflow-y: hidden;
    }
    &__button {
        margin-top: $lumx-spacing-unit-huge;
    }
    &__progress {
        margin-left: auto;
        margin-right: auto;
        margin-top: $lumx-spacing-unit-huge;
    }
}