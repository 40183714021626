@import '@lumapps/lumx/scss/variables.scss';

.block-user-list {
    $self: &;
    &__see-more-button {
        flex-shrink: 0;
        margin-left: auto;
    }

    &--size-l, &--size-m {
        #{$self}__item {
            flex: 1 1 0;
        }
    }

    /* Handle text overflow: */
    > *, .lumx-user-block__wrapper  {
        overflow: hidden;
    }
    span {
        display: inline !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
