@import '@lumapps/lumx/scss/variables.scss';

.metadata-selection {
  &__subtitle {
    @include lumx-typography('subtitle1');
    margin-bottom: $lumx-spacing-unit-huge;
  }

  &__list {
    list-style: none;
    margin: $lumx-spacing-unit-big 0;

    &-item {
      margin: $lumx-spacing-unit-regular 0;
    }
  }

  &__loader {
    margin: $lumx-spacing-unit-big;
  }

  &__warning-message {
    margin-top: $lumx-spacing-unit-regular;
  }
}
