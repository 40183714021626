.block-augmented-container {

  .block-list__load-more,
  .block-grid__load-more {
    margin-top: 0;
  }

  > .lumx-progress {
    margin: 0 auto;
  }
}