@import "@lumapps/lumx/scss/mixins.scss";
@import "@lumapps/lumx/scss/variables.scss";

.block-video__video-placeholder {
  // Wide aspect ratio
  @include ratio(16, 9);
  position: relative;
  background: black;
  // Center play icon
  justify-content: center;
  align-items: center;

  /** Overlay used in hover/focus & active states. */
  &::after {
    @include lumx-state-transition;
    position: absolute;
    inset: 0;
    content: "";
  }

  /* Focus/Hover state */
  &:hover::after,
  &:focus::after {
    @include lumx-state(lumx-base-const("state", "HOVER"), lumx-base-const("emphasis", "LOW"), "light");
  }

  /* Active (pressed) state */
  &:active::after {
    @include lumx-state(lumx-base-const("state", "ACTIVE"), lumx-base-const("emphasis", "LOW"), "light");
  }
}
