@import '../../../../scss/variables.scss';

.navigation-section {
  flex-direction: column;
  align-items: normal;

  &--horizontal {
    max-width: 216px;
  }

  &__drawer {
    padding-left: $lumx-spacing-unit-huge;

    &--popover {
      min-width: 210px;
      padding: $lumx-spacing-unit;
    }
  }

  & &__chevron {
    margin-left: auto;
    margin-right: 0;
    padding-left: $lumx-spacing-unit;
  }
}
