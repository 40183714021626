@import '@lumapps/lumx/scss/variables.scss';

.widget-skeleton {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: transparent;
    box-shadow: unset;

    &--default-height {
        min-height: 400px;
    }

    &__content {
        flex: 1 1 auto;
    }
}
