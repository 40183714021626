@import '@lumapps/lumx/scss/variables.scss';

.video-group-details {
    &__hidden-groups {
        &:hover {
            cursor: pointer;
            text-decoration: underline !important;
        }
    }
}
