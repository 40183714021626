@import '@lumapps/lumx/scss/variables.scss';

.block-playlist-thumbnail {
    $this: &;
    $lumxThumbnail: '.lumx-thumbnail';

    position: relative;

    &__fallback {
        width: 100%;
    }

    &--is-interactive {
        &:hover {
            #{$lumxThumbnail}__background {
                &::after {
                    opacity: 1;
                    visibility: visible;
                }
            }

            #{$this}__icon {
                opacity: 1;
            }

            #{$this}__play-all {
                max-width: 120px;
                opacity: 1;
                visibility: visible;
                transition: opacity 0.3s ease-out, visibility 0.3s ease-out, max-width 0.8s ease-out;
            }

            #{$this}__metadata {
                opacity: 0;
                visibility: hidden;
            }
        }

        #{$lumxThumbnail}__background {
            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                background-color: $lumx-color-dark-L3;
                opacity: 0;
                border-radius: $lumx-border-radius;
                visibility: hidden;
                transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
            }
        }

        #{$this}__icon {
            position: absolute;
            top: 50%;
            left: 50%;
            display: flex;
            align-items: center;
            overflow: hidden;
            opacity: 0.7;
            transform: translate3d(-50%, -50%, 0);
            transition: opacity 0.8s ease-out;
        }

        #{$this}__play-all {
            max-width: 0;
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.8s ease-out, visibility 0.8s ease-out, max-width 0.3s ease-out;
        }

        #{$this}__metadata {
            transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
        }
    }

    &__metadata {
        position: absolute;
        bottom: $lumx-spacing-unit-tiny;
        left: $lumx-spacing-unit-tiny;
        line-height: 1;
        padding: $lumx-spacing-unit-tiny $lumx-spacing-unit-regular;
        background-color: $lumx-color-dark-L1;
        border-radius: $lumx-border-radius;
        transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
    }

    /**
     * Overrides default Lumx Thumbnail component behavior to manage all image aspect-ratios.
     * This way, portrait format images will be displayed maintaining their aspect-ratios,
     * within a 16:9 image container, whith black stripes on both sides.
     */

    :where(#{$lumxThumbnail}__background, #{$lumxThumbnail}__fallback) {
        display: flex;
        aspect-ratio: 16/9;
        border-radius: $lumx-border-radius;
    }

    :where(img) {
        width: 100%;
        height: 100%;
    }

    :where(img:not(#{$this}__fallback, #{$lumxThumbnail}__image--is-loading)) {
        object-fit: contain;
        background: #000;
    }

    &__fallback {
        object-fit: cover;
    }
}
