@import '@lumapps/lumx/scss/variables.scss';

.block-stream-item {
    $block-page-preview: '.block-page-preview';

    // Override the Block page preview rules that is applied when inside a list
    #{$block-page-preview} {
        $bpp: &;

        &__content {
            padding: 0px !important;

            // Override BlockPagePreview's max lines for the Feed
            #{$block-page-preview} {
                &__tags {
                    @include max-lines(1);
                }
                &__title .lumx-link__content {
                    @include max-lines(2);
                }
                &__excerpt {
                    @include max-lines(2);
                }
                &__field .metadata-link-list {
                    @include max-lines(1);

                    li {
                        display: -webkit-inline-box;
                    }
                }
            }
        }

        &__thumbnail-wrapper {
            margin-bottom: $lumx-spacing-unit-big;
        }
    }
}
