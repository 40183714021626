@import '@lumapps/lumx/scss/variables.scss';

.post-list-filter-item {
    &__dropdown {
        width: clamp(216px, 100%, 300px);
        &--theme-dark {
            background-color: $lumx-color-dark-N;
        }
    }
    &__container {
        padding: 0 $lumx-spacing-unit-huge;


        &--theme-dark {
            background-color: $lumx-color-dark-N;
        }

        &__title {
            &--theme-dark {
            color: $lumx-color-light-N
            }
        }
    }

    &__flex-between {
        padding: $lumx-spacing-unit-big 0;
        justify-content: space-between;
    }

    &__more {
        &--hidden {
            visibility: hidden;
        }
    }

    &--hidden {
        display: none;
    }

    &--no-label {
        padding-right: calc($lumx-spacing-unit-huge / 2);

        .lumx-chip__before {
                margin-right: 0;
                padding-left: 0;
        }
    }

    & > .lumx-skeleton-rectangle {
        flex-shrink: 0;
    }
}
