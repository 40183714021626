.site-block,
.site-block-skeleton {
    justify-content: space-between;

    &--orientation-vertical &__content {
        align-items: center;
     }

  .lumx-tooltip-anchor-wrapper {
    margin: auto;
  }
}
