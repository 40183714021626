@import '@lumapps/lumx/scss/variables.scss';

@mixin overlay($color: $lumx-color-dark-L3) {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: $color;
}
