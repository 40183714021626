@import '@lumapps/lumx/scss/variables.scss';

.video-player__skeleton {
    position: relative;
    width: 100%;

    &__rectangle {
        background: $lumx-color-dark-L1;
        animation: none;
    }

    &__progress {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
