@import '@lumapps/lumx/scss/variables.scss';

.play-droppable-file-zone {
    padding: $lumx-spacing-unit-huge;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__subtitle {
        margin-top: $lumx-spacing-unit-huge;
    }

    &__description {
        margin-top: $lumx-spacing-unit-tiny;
        color: $lumx-color-dark-L2;
    }

    &__formats {
        margin: $lumx-spacing-unit-huge auto;
        color: $lumx-color-dark-L2;
        justify-self: flex-end;
    }
    // Override the draggable zone to hide the uploader behind
    .droppable-file-zone {
        &__content {
            display: flex;
            flex-direction: column;
            align-items: center;
            &--dragging {
                opacity: 0;
            }
        }
    }
}
