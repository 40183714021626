@import '@lumapps/lumx/scss/variables';

.widget-mandatory-read-block {
    &__wrapper {
        position: relative;
        @include mq($until: mobile) {
            flex-direction: column !important;
        }

        &--is-loading {
            & .widget-mandatory-read-block__icon,
            & .widget-mandatory-read-block__text {
                opacity: 0.5;
            }
        }

        &--is-read {
            background: lumx-color-variant('green', 'L6'); /* css script ignore */

            .widget-mandatory-read-block__title--theme-light {
                color: lumx-color-variant('green', 'D2'); /* css script ignore */
            }

            .widget-mandatory-read-block__icon {
                color: lumx-color-variant('green', 'N'); /* css script ignore */
            }
        }
    }


    &__button {
        margin: auto 0 auto auto !important;
        flex-shrink: 0;
        @include mq($until: mobile) {
            margin: $lumx-spacing-unit 0 0 $lumx-size-m !important;
        }
    }

    &__loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
