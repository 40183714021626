@import '@lumapps/lumx/scss/variables.scss';

.widget-play-uploader {
  &.widget-play-uploader__input {
    width: 100%;
  }

  &__title {
      justify-content: space-between;
  }

  &__subtitle {
      margin-bottom: $lumx-spacing-unit-huge;

      @include lumx-typography('subtitle1');
  }

  &__selected-video {
    display: flex;
    padding: $lumx-spacing-unit-big;
    background-color: lumx-color-variant('dark', 'L6');
  }

  &__video-block {
    width: calc(100% - #{$lumx-size-m});
  }

  .lumx-skeleton-rectangle{
    height: map-get($lumx-sizes, 'l');
  }
}
