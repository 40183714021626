@import '@lumapps/lumx/scss/variables.scss';

.sub-navigation-element {
    .navigation-item__label {
        white-space: initial;
        text-overflow: initial;
        overflow: initial;
        text-align: left;

        padding-top: $lumx-spacing-unit;
        padding-bottom: $lumx-spacing-unit;
    }
    .navigation-section__drawer,
    .navigation-section-link__drawer {
        padding-top: $lumx-spacing-unit;
        padding-bottom: $lumx-spacing-unit;
    }
}
