@import '../../../scss/variables.scss';

.masonry {
    &__column {
        @each $key, $size in $lumx-spacing-sizes {
            &--gutter#{$key} {
                &+& {
                    margin-left: $size;
                }

                > * {
                    margin-bottom: $size;
                }
            }
        }
    }
}
