@import '@lumapps/lumx/scss/variables.scss';

.video-metadata-selection {
    &__subtitle {
        @include lumx-typography('subtitle1');
        margin-bottom: $lumx-spacing-unit-huge;
    }

    .lumx-text-field {
        padding: 0;
    }

    &__displayed-video {
        margin-bottom: $lumx-spacing-unit-huge;
    }
}
