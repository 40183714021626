.generic-entity-picker {
    width: 100%;

    &__search-field {
        padding: 0 !important;

        &--full-width {
            width: 100%;
        }

        .lumx-list-subheader i {
            margin: 0 !important;
        }
    }
}
