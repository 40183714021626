@import '@lumapps/lumx/scss/variables.scss';

.metadata-selection-block {

    &__category {
        @include lumx-typography('body1');
        overflow: hidden;
        text-overflow: ellipsis;

        &--theme-dark {
            color: lumx-color-variant('light', 'N')
        }

        &--theme-light {
            color: lumx-color-variant('dark', 'N')
        }
    }
}
