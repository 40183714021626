@import '@lumapps/lumx/scss/variables.scss';

.video-player__metadata {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;

    &__banner {
        padding: $lumx-spacing-unit-medium;
        padding-bottom: $lumx-spacing-unit-huge;
    }

    /** ⚠️ Enable pointer events on interactive elements ⚠️ */
    &__video-title,
    &__video-author {
        pointer-events: auto;
    }

    &__video-title {
        width: 100%;

        .lumx-link__content {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    &__video-duration {
        position: absolute;
        bottom: $lumx-spacing-unit-tiny;
        left: $lumx-spacing-unit-tiny;
        line-height: 1;
        padding: $lumx-spacing-unit-tiny $lumx-spacing-unit-regular;
        background-color: $lumx-color-dark-L1;
        border-radius: $lumx-border-radius;
    }
}
