@import '@lumapps/lumx/scss/variables.scss';

$progress-bar-height: 6px;

.training-card {
    &__box {
        background: $lumx-color-light-N;
        text-decoration: none; 
        color: inherit;
        display: block;
        border-radius: var(--lumx-border-radius);
        &:hover {
            text-decoration: none; 
        }
    }
    &__box_with_shadow {
        @include lumx-elevation(2)
    }
    &__img img {
        border-radius: var(--lumx-border-radius);
    }
    &__progress-bar {
        position: relative;
        bottom: $progress-bar-height;
        overflow: clip;
        border-bottom-left-radius: var(--lumx-border-radius);
        border-bottom-right-radius: var(--lumx-border-radius);
        background-color: $lumx-color-light-N;
    }
}