@import '@lumapps/lumx/scss/variables.scss';

.directory-entry-block {
    $this: &;

    display: flex;

    &--horizontal {
        align-items: center;
    }

    &--vertical {
        height: 100%;
        flex-direction: column;
        align-items: center;
    }

    &__thumbnail-wrapper {
        text-decoration: none;

        #{$this}--horizontal & {
            margin-right: $lumx-spacing-unit-big;
        }

        #{$this}--vertical & {
            margin-bottom: $lumx-spacing-unit-big;
        }

        .block-image {
            #{$this}--horizontal & {
                width: var(--lumx-directory-entry-block-orientation-h-thumbnail-size);
            }

            #{$this}--vertical & {
                width: var(--lumx-directory-entry-block-orientation-v-thumbnail-size);
            }
        }
    }

    &__letter {
        #{$this}--horizontal & {
            width: var(--lumx-directory-entry-block-orientation-h-thumbnail-size);
            height: var(--lumx-directory-entry-block-orientation-h-thumbnail-size);
        }

        #{$this}--vertical & {
            width: var(--lumx-directory-entry-block-orientation-v-thumbnail-size);
            height: var(--lumx-directory-entry-block-orientation-v-thumbnail-size);
        }
    }

    &__content {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;

        #{$this}--vertical & {
            align-items: center;
            text-align: center;
        }
    }

    &__title {
        @include lumx-typography('title5', 'custom');

        #{$this}--theme-light & {
            color: var(--lumx-directory-entry-block-title-theme-light-color);
        }

        #{$this}--theme-dark & {
            color: var(--lumx-directory-entry-block-title-theme-dark-color);
        }
    }

    &__tags {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: -$lumx-chip-group-spacing 0;
    }

    &__tags {
        &>span {
            display: flex;
            align-items: center;

            #{$this}--theme-light & {
                &::after {
                    @include tag-separator-style('tag', lumx-base-const('theme', 'LIGHT'));
                }

                span {
                    @include tag-style('tag', lumx-base-const('theme', 'LIGHT'));
                }
            }

            #{$this}--theme-dark & {
                &::after {
                    @include tag-separator-style('tag', lumx-base-const('theme', 'DARK'));
                }

                span {
                    @include tag-style('tag', lumx-base-const('theme', 'DARK'));
                }
            }

            &:last-child::after {
                display: none;
            }
        }
    }

    &__actions {
        #{$this}--horizontal & {
            margin-left: $lumx-spacing-unit;
        }

        #{$this}--vertical & {
            margin-top: $lumx-spacing-unit;
        }
    }
}
