@import '@lumapps/lumx/scss/variables.scss';

.widget-play-settings {
    padding: $lumx-spacing-unit-huge $lumx-spacing-unit-big;
    hr {
        margin: $lumx-spacing-unit-huge (-$lumx-spacing-unit-big);
    }

    &__setting {
        margin-bottom: $lumx-spacing-unit-tiny;
        color: $lumx-color-dark-L2;

        @include lumx-typography('caption');
    }
}
