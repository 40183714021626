@import '@lumapps/lumx/scss/variables.scss';

$width: 600px;

.upload-video-dialog {
    .lumx-dialog__container {
        @include mq($from: tablet) {
            width: $width;
            left: 50%;
            margin-left: -($width * 0.5);
        }
    }

    .lumx-dialog__wrapper {
        height: 555px;
    }

    .lumx-dialog__content {
        display: flex;
        flex: 1;
        padding: 0 $lumx-spacing-unit-huge;
    }

    .button-uploader {
        flex: 1;
    }


    &__uploader {
        width: 230px;
    }

    &__subtitle {
        @include lumx-typography('subtitle2');
        margin-top: $lumx-spacing-unit-huge;
    }

    &__description {
        @include lumx-typography('body1');

        margin-top: $lumx-spacing-unit-tiny;
        color: $lumx-color-dark-L2;
    }
}
