@use 'sass:list';

@import '@lumapps/lumx/scss/variables.scss';

.video-status-flag {
    @include lumx-typography('overline');

    // component variables
    $video-status-flag-v-gap: 0.2em;
    $video-status-flag-h-gap: 0.8em;
    $video-status-flag-border-width: 0.2em;
    $video-status-flag-bg-color: $lumx-color-light-N;

    text-transform: uppercase;
    padding: $video-status-flag-v-gap $video-status-flag-h-gap;
    border-radius: list.slash($video-status-flag-h-gap, 50%);
    background: $video-status-flag-bg-color;
    border-width: $video-status-flag-border-width;
    border-style: solid;

    &--yellow {
        color: $lumx-color-yellow-D2;
        border-color: $lumx-color-yellow-L4;
    }

    &--green {
        color: $lumx-color-green-D2;
        border-color: $lumx-color-green-L4;
    }

    &--dark {
        color: $lumx-color-dark-N;
        border-color: $lumx-color-dark-L6;
    }

    &--red {
        color: $lumx-color-red-D2;
        border-color: $lumx-color-red-L4;
    }

    &--skeleton {
        width: 12ch;
    }
}
