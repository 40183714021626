@import '../../../scss/variables.scss';

.droppable-file-zone {
  position: relative;
  
  &__wrapper {
    pointer-events: none;
    @include fit-parent;
    background-color: $lumx-color-primary-L5;
    border-style: dashed;
    border-color: $lumx-color-primary-N;
    border-width: 2px;
    border-radius: 2px;
    padding-top: $lumx-spacing-unit-huge;
    padding-bottom: $lumx-spacing-unit-huge;
  }

  &__content {
    &--dragging {
      opacity: 0.5;
    }
  }
}