@import '@lumapps/lumx/scss/variables.scss';

.block-video-link {
    display: inline-block;
    vertical-align: middle;
    color: inherit;

    &--is-truncated {
        overflow: hidden;
    }

    &--is-stretched {
        text-decoration: none !important;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        &:focus-visible {
            &::before {
                box-shadow: inset 0 0 0 $lumx-text-field-state-focus-input-border-top-width var(--lumx-color-dark-N);
            }
        }
    }
}
