@import '@lumapps/lumx/scss/variables.scss';

.metadata-link {

    &--theme-light {
        @include tag-style('metadata', lumx-base-const('theme', 'LIGHT'));
    }

    &--theme-dark {
        @include tag-style('metadata', lumx-base-const('theme', 'DARK'));
    }
    text-decoration: none !important;
    overflow: hidden;

    &--is-link:focus {
        text-decoration: revert !important;
    }

    // Needed to make metadata truncature possible
    span {
        overflow: hidden;
    }
}
