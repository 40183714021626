@import '@lumapps/lumx/scss/variables.scss';

.widget-breadcrumb {
    &__skeleton {
        width: 500px;
    }

    &__wrapper {
        display: flex;
        align-items: center;
        list-style: none;
    }

    &__list-item {
        display: flex;
        align-items: center;
    }

    &__link {
        @include lumx-link-base;
    }

    &__link,
    &__section {
        @include lumx-typography('subtitle1');
        @include lumx-link-color('dark', 'N');

        .widget--theme-dark & {
            @include lumx-link-color('light', 'N');
        }

        &--last {
            @include lumx-link-color('primary', 'N');

            .widget--theme-dark & {
                @include lumx-link-color('light', 'N');
            }
        }
    }

    &__separator {
        margin: 0 $lumx-spacing-unit-tiny;
    }
}
