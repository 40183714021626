@import '@lumapps/lumx/scss/variables.scss';

.widget-user-list {
  .lumx-user-block .lumx-user-block__name {
    @include lumx-typography('subtitle2');
  }
}

.block-user-profile-preview {
  &__field {
    width: 100%;
    min-width: 0;
  }

  &__content, &__list, &.lumx-user-block {
    overflow: hidden;
  }
}
