@import '@lumapps/lumx/scss/variables.scss';

.widget-content--block-title {
    overflow: visible;
    padding: $lumx-spacing-unit-huge;
    justify-content: var(--lumx-widget-title-alignment-v);
    align-items: var(--lumx-widget-title-alignment-h);
}

.block-title {
    @include lumx-typography('title1', 'custom');

    text-transform: var(--lumx-widget-title-text-transform);
    text-align: var(--lumx-widget-title-text-align);
    word-break: break-word;

    .widget--theme-light & {
        color: var(--lumx-widget-title-theme-light-color);
    }

    .widget--theme-dark & {
        color: var(--lumx-widget-title-theme-dark-color);
    }
}

