@import '@lumapps/lumx/scss/variables.scss';

.block-community-preview {
    $this: &;

    &--mobile#{&}--size-xs#{&}--horizontal {
        flex-direction: column;
        align-items: normal;
    }

    &__thumbnail-wrapper {
        display: block;

        #{$this}--horizontal & {
            min-width: map-get($lumx-sizes, m);
            max-width: 280px;
            flex-basis: 40%;
            flex-shrink: 0;
            margin-right: $lumx-spacing-unit-huge;
        }

        #{$this}--size-xs#{$this}--horizontal & {
            flex: 0 0 map-get($lumx-sizes, m);
        }

        #{$this}--mobile#{$this}--size-xs#{$this}--horizontal & {
            margin-bottom: $lumx-spacing-unit-huge;
            margin-right: 0;
            max-width: 100%;
            flex: auto;
        }

        #{$this}--vertical & {
            margin-bottom: $lumx-spacing-unit-huge;
        }

        &--small {
            margin-bottom: unset !important;
        }

        #{$this}--size-l#{$this}--side & {
            width: 70%;
            flex: 1 1 70%;
        }
    }

    &__content {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        #{$this}--size-l#{$this}--side & {
            flex: 1 1 30%;
        }

        #{$this}--vertical-stretch & {
            padding: $lumx-spacing-unit-huge;
        }

        #{$this}__small-thumbnail-wrapper {
            margin-bottom: $lumx-spacing-unit-tiny;
        }

        #{$this}__small-thumbnail-aside {
            align-self: center;
        }
    }

    &__field {
        &--block {
            margin: $lumx-spacing-unit-big 0;
        }

        &--text {
            margin: $lumx-spacing-unit-tiny * 0.5 0;
        }

        &:first-child {
            margin-top: 0 !important;
        }

        &:last-child {
            margin-bottom: 0 !important;
        }

        &-text--line-clamp {
            @include max-lines(2);
        }
    }

    &__site {
        @include lumx-typography('body1');

        #{$this}--theme-light & {
            color: $lumx-color-dark-L2;
        }

        #{$this}--theme-dark & {
            color: $lumx-color-light-L2;
        }
    }

    &__title {
        @include lumx-typography('title4', 'custom');

        #{$this}--theme-light & {
            color: var(--lumx-community-block-title-theme-light-color);
        }

        #{$this}--theme-dark & {
            color: var(--lumx-community-block-title-theme-dark-color);
        }
    }

    &__description {
        @include lumx-typography('body', 'custom');

        #{$this}--theme-light & {
            color: lumx-color-variant('dark', 'N');
        }

        #{$this}--theme-dark & {
            color: lumx-color-variant('light', 'N');
        }
    }
}
