@import '@lumapps/lumx/scss/variables.scss';

.block-personal-feed-preview {
    &-header--ungrouped {
        overflow: hidden;
        border-radius: 4px;
        padding: $lumx-spacing-unit-huge;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
    }

    &-footer {
        &--grouped {
            margin-top: $lumx-spacing-unit-huge;
        }
        &--button-wrapper {
            padding: $lumx-spacing-unit-huge;
            border-radius: 4px;
        }
    }
}
