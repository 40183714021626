@import '@lumapps/lumx/scss/variables.scss';

.custom-radio-list {
    &__item {
        flex-grow: 1;
    }
    &__item:hover, &__item:focus {
        background-color: $lumx-color-dark-L6;
        cursor: pointer;
    }
    &__item--is-checked {
        background-color: $lumx-color-dark-L6;
    }
}
