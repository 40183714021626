@import '@lumapps/lumx/scss/variables.scss';

.widget-play {

    &__error {
        @include ratio;
        display: flex;
        align-items: center;
        justify-content: center;
    }

}

/// This part below avoids playing video on click when in designer mode.
.content--designer-mode .widget-play .block-play__video-player {
    // Allow hover (author infos) + click (link title)
    .video-player-metadata {
        &__link, &__user-block {
            pointer-events: initial;
        }
    }
}
