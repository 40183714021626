@import '@lumapps/lumx/scss/variables.scss';

.block-author {
    $self: &;

    display: flex;
    align-items: flex-start;

    &--orientation-vertical {
        flex-direction: column;
    }

    &__avatar {
        margin-right: $lumx-spacing-unit-big;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
    }

    &__name {
        @include lumx-typography('body1');
    }

    &__tags {
        color: lumx-color-variant('primary', 'N');
    }
}
