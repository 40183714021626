@import '@lumapps/lumx/scss/variables.scss';

.learning-category {
    &__wrapper {
        cursor: pointer;
    }
    &__box {
        margin-top: $lumx-spacing-unit-big;
        display:flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        height: $lumx-size-m;
    }

}
