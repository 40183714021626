@import '@lumapps/lumx/scss/variables.scss';

.play-block {
    @include lumx-typography('body1');

    &--dark {
        color: lumx-color-variant(light, L2);
    }

    &--light {
        color: lumx-color-variant(dark, L2);
    }

    &__figure {
        &--l-horizontal,
        &--m-horizontal {
            align-self: start;
        }
    }

    &__content {
        overflow: hidden;
        // 👆 This is required to allow text overflow ellipsis on extra long title for example.
    }
}
