@import '@lumapps/lumx/scss/variables.scss';

.generic-switch-list {
    &__list-item {
        list-style: none;

        &:not(:last-of-type) {
            margin-bottom: $lumx-spacing-unit-regular;
        }
    }

}
