@import '@lumapps/lumx/scss/variables.scss';
@import '@lumapps/play-core/scss/mixins.scss';
@import '@lumapps/play-core/scss/placeholders.scss';

.video-player__thumbnail {
    $this: &;
    $lumxThumbnail: '.lumx-thumbnail';

    position: relative;

    &:hover {
        #{$this}__play-icon { // means .video-player__thumbnail__play-icon
            opacity: 1;
        }
    }

    /**
     * Overrides default Lumx Thumbnail component behavior to manage all image aspect-ratios.
     * This way, portrait format images will be displayed maintaining their aspect-ratios,
     * within a 16:9 image container, whith black stripes on both sides.
     */

    :where(#{$lumxThumbnail}__background, #{$lumxThumbnail}__fallback) {
        display: flex;
        aspect-ratio: 16/9;
    }

    &__image {
        width: 100%;

        &::after {
            @include overlay($lumx-color-dark-L3);
        }
    }

    :where(img) {
        width: 100%;
        height: 100%;
    }

    :where(img:not(#{$this}__fallback, #{$lumxThumbnail}__image--is-loading)) {
        object-fit: contain;
        background: #000;
    }

    &__fallback {
        object-fit: cover;
    }

    &__play-icon {
        @extend %absoluteCenter;

        color: $lumx-color-light-L3;
        opacity: 0.7;
        pointer-events: none;
        transition: opacity 0.3s ease-out;
    }
}
