@import '@lumapps/lumx/scss/variables.scss';


.widget-content--v2 {
    display: flex;
    flex-direction: column;
    border-width: 0;
    border-style: solid;
    color: lumx-color-variant('dark', 'N');

    // `ungrouped` widget have items with shadows that need to overflow outside the widget content.
    // Other widgets should have scrollable overflow by default.
    &:not(.widget-content--block-variant-ungrouped):not(.widget-content--block-augmented-container) {
        overflow: auto;
    }

    // Default font color on dark theme
    .widget--theme-dark & {
        color: lumx-color-variant('light', 'N');
    }

    // Content loading
    & > .lumx-progress {
        margin: $lumx-spacing-unit * 2 auto;
    }

    // Adapt the default padding (if block variant is not `ungrouped`, `side` or `cover`)
    &:not(.widget-content--block-variant-ungrouped):not(.widget-content--block-variant-side):not(.widget-content--block-variant-cover) {
        padding: $lumx-spacing-unit-huge;
    }

    // Adapt the default overflow on `cover` block variant
    &.widget-content--block-variant-cover {
        overflow: hidden;
    }
}

.widget-content--hidden {
    display: none;
}

.widget--is-full-height .widget-content--v2 {
    flex: 1 0 auto;
}

/* Plain cell css */
// on v1 page
.component-cell--is-plain .widget,
// on v2 page
.cell--is-plain .widget
{
    .widget-content--v2,
    .widget-content--v2:not(.widget-content--block-variant-ungrouped):not(.widget-content--block-variant-side):not(.widget-content--block-variant-cover) {
        padding: 0;
        background: none;
    }

    &.widget--has-header .widget-content--v2,
    &.widget--has-header .widget-content--v2:not(.widget-content--block-variant-ungrouped):not(.widget-content--block-variant-side):not(.widget-content--block-variant-cover) {
        padding-top: $lumx-spacing-unit-huge;
    }

    &.widget--has-footer .widget-content--v2,
    &.widget--has-footer .widget-content--v2:not(.widget-content--block-variant-ungrouped):not(.widget-content--block-variant-side):not(.widget-content--block-variant-cover) {
        padding-bottom: $lumx-spacing-unit-huge;
    }
}
