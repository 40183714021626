@import '@lumapps/lumx/scss/variables.scss';

.block-html {
    @include lumx-typography('body-large', 'custom');

    line-height: inherit;

    hr {
        border: 0;
        border-bottom: 1px solid $lumx-color-dark-L4;
    }

    u {
        text-decoration: underline;
    }

    a {
        color: lumx-color-variant('primary', 'N');
    }

    img {
        display: block;
        // Scale down to make images responsive
        max-width: 100%;

        // Disable image scale down since the image as explicit size constraint
        &[width], &[height] {
            max-width: none;
        }

        &.fr-dib {
            margin: 0 auto;
        }

        &.fr-dib.fr-fil {
            margin-left: 0;
        }

        &.fr-dib.fr-fir {
            margin-right: 0;
        }

        &.fr-dii {
            display: inline-block;
        }

        &.fr-dii.fr-fil {
            margin-right: $lumx-spacing-unit * 3;
            float: left;
        }

        &.fr-dii.fr-fir {
            margin-left: $lumx-spacing-unit * 3;
            float: right;
        }

        &.fr-img-rounded {
            border-radius: 50%;
        }
    }

    table {
        max-width: 100%;
        border: 0;
        border-collapse: collapse;
        empty-cells: show;
        width: 100%;

        th {
            background-color: var(--lumx-color-dark-L5);
        }

        td,
        th {
            padding: $lumx-spacing-unit * 1.5;
            border: 1px solid $lumx-color-dark-L5;
            word-wrap: break-word;

            &.fr-selected-cell {
                border-style: double;
                border-color: $lumx-color-primary-N;
            }
        }

        &.fr-table-no-border,
        &.table--no-border {
            td,
            th {
                border: 0;
            }
        }

        &.fr-table-alternate-rows {
            tbody tr:nth-child(2n) {
                background-color: var(--lumx-color-dark-L6);
            }
        }
    }

    blockquote {
        position: relative;
        padding-left: $lumx-spacing-unit * 2.5;

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            width: $lumx-spacing-unit-tiny;
            background-color: lumx-color-variant('dark', 'L4');
            border-radius: 2px;
        }
    }

    ul,
    dl,
    ol {
        margin-left: 32px;
    }

    address,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    blockquote,
    p,
    pre,
    dl,
    ol,
    ul,
    figure,
    hr,
    table,
    fieldset {
        &:not(:last-child) {
            margin-bottom: $lumx-spacing-unit * 3;
        }
    }

    h1 {
        @include lumx-typography('title1', 'custom');
    }

    h2 {
        @include lumx-typography('title2', 'custom');
    }

    h3 {
        @include lumx-typography('title3', 'custom');
    }

    h4 {
        @include lumx-typography('title4', 'custom');
    }

    h5 {
        @include lumx-typography('title5', 'custom');
    }

    h6 {
        @include lumx-typography('title6', 'custom');
    }

}

.block-html--dark{
    p, a {
        color: var(--lumx-widget-title-text-dark-color);
    }
    blockquote::before {
        background-color: lumx-color-variant('light', 'L2');
    }
}

.block-html-shadow-links {
    display: none;
}
