@import '../../../../scss/variables.scss';

// The spacing is specific to the chevron so it aligns with the button of NavigationSection component
$chevron-spacing: 3px;

.navigation-section-link {
    flex-direction: column;
    align-items: normal;

    &__section {
        flex-direction: row;
        align-items: center;
        padding-right: $chevron-spacing;
    }

    &__link {
        flex-grow: 1;
    }

    &__drawer {
        padding-left: $lumx-spacing-unit-huge;
    }

    & &__chevron {
        margin-left: $chevron-spacing;
        margin-right: 0;
        justify-content: center;
        flex-shrink: 0;
    }
}
