@import '@lumapps/lumx/scss/variables.scss';

// Override the default padding's for the BlockGrid items, when in ungrouped mode
// This is to show the ImageBlock in full width/height
.widget-image-gallery {
  /**
   * Fix outline hidden when cell is structured
   * or when cell is fixed
   */
  &.widget, .widget-content:not(.widget-content--has-fixed-height) {
    overflow: visible !important;
  }

  .block-grid--ungrouped .block-grid__item {
    padding: 0;
    margin-bottom: auto;
  }
  .lumx-image-block__wrapper{
    overflow: hidden;
  }

  .widget--is-full-height,
  .widget-content--has-fixed-height {
    .block-slideshow,
    .#{$lumx-base-prefix}-slideshow,
    .#{$lumx-base-prefix}-slideshow__slides,
    .#{$lumx-base-prefix}-slideshow__wrapper {
        height: 100%;
        width: 100%;
    }
    .block-image-preview, .lumx-thumbnail, .lumx-thumbnail__background{
      height: 100%;
      width: 100%;
      padding: 0px;
    }
    .block-slideshow__controls{
      margin: 0px;
    }

    .block-slideshow--grouped, .block-slideshow--ungrouped{
      margin: 0px;
    }

    .block-slideshow--grouped .block-slideshow__item-wrapper{
      padding: 0px;
    }
  }
}

.block-image-preview.lumx-image-block--caption-position-below {
  .lumx-image-block__wrapper {
    padding: var(--lumx-spacing-unit-big);
  }
}
