@import '@lumapps/lumx/scss/variables.scss';

.block-grid {
  $self: &;

  &--ungrouped {
    #{$self}__item {
      overflow: hidden;
      background-color: $lumx-color-light-N;
      border-radius: 2px;
      padding: $lumx-spacing-unit-huge;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);

      &--no-padding {
        padding: 0;
      }
    }
  }

  &__item {
    border: 0 solid lumx-color-variant('dark', 'L5');
  }

  &__load-more {
    margin-top: $lumx-spacing-unit-huge;
  }
}
