@import '@lumapps/lumx/scss/variables.scss';

.block-cascade__item {
    border: 0 solid lumx-color-variant('dark', 'L5');
    background-color: $lumx-color-light-N;
    border-radius: 2px;
}

.block-cascade:empty + .block-cascade__load-more {
    display: none;
}
