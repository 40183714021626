@import '@lumapps/lumx/scss/variables.scss';

.block-page-preview {
    $this: &;

    position: relative;
    display: flex;

    &--horizontal,
    &--side,
    &--cover {
        flex-direction: row;
    }

    &--vertical,
    &--mobile#{&}--size-xs#{&}--horizontal,
    &--size-xs#{&}--side {
        flex-direction: column;
    }

    &--cover {
        align-items: flex-end;
    }

    &--side,
    &--cover {
        height: 100%;
    }
    &--is-preview-only {
        width: 100%;
    }

    &__thumbnail-wrapper {
        display: block;
        #{$this}--horizontal & {
            min-width: map-get($lumx-sizes, m);
            max-width: 280px;
            flex: 0 0 40%;
            margin-right: $lumx-spacing-unit-huge;
        }
        #{$this}--size-xs#{$this}--horizontal & {
            flex: 0 0 map-get($lumx-sizes, m);
        }

        #{$this}--mobile#{$this}--size-xs#{$this}--horizontal & {
            margin-bottom: $lumx-spacing-unit-huge;
            margin-right: 0;
            max-width: 100%;
            flex: auto;
        }

        #{$this}--vertical & {
            margin-bottom: $lumx-spacing-unit-huge;
        }

        #{$this}--size-s#{$this}--side &,
        #{$this}--size-m#{$this}--side & {
            width: 50%;
            flex: 1 1 50%;
        }

        #{$this}--size-l#{$this}--side & {
            width: 70%;
            flex: 1 1 70%;
        }

        #{$this}--cover & {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        .block-list--ungrouped &,
        .block-grid--ungrouped &,
        .block-slideshow--ungrouped &,
        .block-slideshow--side & {
            margin: 0;
        }
        &--is-preview-only {
            min-width: 40% !important;
        }
    }

    &__content {
        position: relative;
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        overflow: hidden;

        #{$this}--size-l#{$this}--cover & {
            padding-top: $lumx-spacing-unit * 20;
            padding-right: $lumx-spacing-unit * 25;
        }

        #{$this}--size-l#{$this}--side & {
            flex: 1 1 30%;
        }

        #{$this}--size-s#{$this}--side &,
        #{$this}--size-m#{$this}--side & {
            flex: 1 1 50%;
        }

        #{$this}--cover & {
            position: initial;
            padding: $lumx-spacing-unit-huge;


            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 28%, rgba(0, 0, 0, 0.87) 100%);
                pointer-events: none;
            }
        }

        .block-list--ungrouped &,
        .block-grid--ungrouped &,
        .block-slideshow--ungrouped &,
        .block-slideshow--side & {
            padding: $lumx-spacing-unit-huge;
        }
        &--is-preview-only {
            max-width: 60%;
        }
    }

    &__field {
        #{$this}--cover & {
            position: relative;
            margin-right: auto;
        }
        &--block {
            margin: $lumx-spacing-unit-big 0;
        }

        &--text {
            margin: $lumx-spacing-unit-tiny * 0.5 0;
        }

        &--reactions {
            margin: $lumx-spacing-unit 0;
            margin-left: -$lumx-spacing-unit;
        }

        &:first-child {
            margin-top: 0 !important;
        }

        &:last-child {
            margin-bottom: 0 !important;
        }
    }

    &__date, &__site {
        @include lumx-typography('publish-info', 'custom');

        #{$this}--theme-light & {
            color: lumx-color-variant('dark', 'L2');
        }

        #{$this}--theme-dark & {
            color: lumx-color-variant('light', 'L2');
        }
    }

    &__tags {
        margin: -$lumx-chip-group-spacing 0;

        & > span {
            &:last-child::after {
                display: none;
            }
        }
    }

    &__tags {
        & > span {
            &::after {
                #{$this}--theme-light & {
                    @include tag-separator-style('tag', lumx-base-const('theme', 'LIGHT'));
                }

                #{$this}--theme-dark & {
                    @include tag-separator-style('tag', lumx-base-const('theme', 'DARK'));
                }
            }

            & > span {
                #{$this}--theme-light & {
                    @include tag-style('tag', lumx-base-const('theme', 'LIGHT'));
                    display: inline;
                }

                #{$this}--theme-dark & {
                    @include tag-style('tag', lumx-base-const('theme', 'DARK'));
                    display: inline;
                }
            }
        }
    }

    &__title {
        word-break: break-word;

        #{$this}--size-xs#{$this}--side &,
        #{$this}--size-xs#{$this}--cover &,
        #{$this}--size-s#{$this}--side &,
        #{$this}--size-s#{$this}--cover &,
        #{$this}:not(#{$this}--side) &,
        #{$this}:not(#{$this}--cover) & {
            @include lumx-typography('title4', 'custom');
        }

        #{$this}--size-m#{$this}--side &,
        #{$this}--size-l#{$this}--side &,
        #{$this}--size-m#{$this}--cover &,
        #{$this}--size-l#{$this}--cover & {
            @include lumx-typography('title3', 'custom');
        }
    }

    &__link {
        text-transform: var(--lumx-content-block-title-text-transform);

        #{$this}--theme-light & {
            color: var(--lumx-content-block-title-theme-light-color);
        }

        #{$this}--theme-dark & {
            color: var(--lumx-content-block-title-theme-dark-color);
        }
    }

    &__excerpt {
        overflow-wrap: break-word;
        @include lumx-typography('body', 'custom');

        #{$this}--theme-light & {
            color: $lumx-color-dark-N;
        }

        #{$this}--theme-dark & {
            color: $lumx-color-light-N;
        }
    }

    .lumx-user-block__wrapper,
    .lumx-user-block__name,
    .lumx-user-block__field {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.block-slideshow--with-navigation .block-page-preview--side .block-page-preview__content,
.block-slideshow--with-navigation .block-page-preview--cover.block-page-preview--size-xs .block-page-preview__content,
.block-slideshow--with-navigation .block-page-preview--cover.block-page-preview--size-s .block-page-preview__content,
.block-slideshow--with-navigation .block-page-preview--cover.block-page-preview--size-m .block-page-preview__content {
    padding-bottom: $lumx-spacing-unit-huge + map-get($lumx-sizes, lumx-base-const('size', 'M')) +
        $lumx-spacing-unit-big;
}
