@import '@lumapps/lumx/scss/variables.scss';

.metadata-link-list {
    display: flex;
    flex-wrap: wrap;
    margin: -$lumx-chip-group-spacing 0;

    &--theme-dark {
        li {
            &::before {
                @include tag-separator-style('metadata', lumx-base-const('theme', 'DARK'));
            }
        }
    }

    &--theme-light {
        li {
            &::before {
                @include tag-separator-style('metadata', lumx-base-const('theme', 'LIGHT'));
            }
        }
    }

    li {
        overflow: hidden;
        display: flex;
        align-items: center;

        &:first-child::before {
            display: none;
        }
    }
}
