@import '@lumapps/lumx/scss/variables.scss';

$lumx-progress-bar-height: 6px;

.progress-bar {
    flex-grow: 1;

    &--variant-rounded &__bars {
        overflow: clip;
        border-radius: $lumx-progress-bar-height;
    }

    &__bar {
        flex-grow: 1;
        height: $lumx-progress-bar-height;
    }

    &__label {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
    }
}

