@import '@lumapps/lumx/scss/variables.scss';

.block-list {
    $this: &;

    --block-list-gap: var(--lumx-spacing-unit-huge);
    display: flex;
    gap: var(--block-list-gap);

    &--vertical {
        flex-direction: column;
    }

    &--horizontal {
        flex-direction: row;
        flex-wrap: wrap;
    }

    &__item {
        #{$this}--grouped > & {
            border: 0 solid lumx-color-variant('dark', 'L5');
        }

        #{$this}--ungrouped > & {
            background-color: lumx-color-variant('light', 'N');
            border-radius: 4px;
            border: 0 solid lumx-color-variant('dark', 'L5');
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14);
        }

        #{$this}--vertical#{$this}--ungrouped > & {
            padding: var(--lumx-spacing-unit-huge);
        }
    }

    &__load-more {
        --block-list-gap: var(--lumx-spacing-unit-huge);

        margin-top: var(--block-list-gap);
    }
}
